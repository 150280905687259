<template>
	<div class="page">
		<div class="page-header">
			<div class="title relative">【推品小助手】
				<button class="close-button button fs-12" @click="$router.back()">关闭</button>
			</div>
			<div class="tips mt-1">现在已经是店主们卖货必备小工具啦</div>
			<div class="tips">但除了代替店主分享外</div>
			<div class="tips">帮助店主出单才是最最最重要的</div>
			<div class="tips-1 mt-4">
				所以【官方素材】<span class="tips-1__important">全面升级</span> 啦
			</div>
			<div class="tips-2 mt-4">
				新增<span class="tips-2__important">【新人专区】</span>和<span class="tips-2__important">【精简素材】</span>
			</div>
			<div class="tips mt-4">两个素材组</div>
			<div class="tips">满足店主大大们的不同需求~</div>
		</div>
		<img class="mt-2" src="./assets/banner-intro.png" alt="">
		<img class="mt-2" src="./assets/banner-step.png" alt="">
	</div>
</template>

<script>
export default {
	name: 'levelUpgradeBanner'
}
</script>

<style lang="scss" scoped>
.page {
	background-color: #fff;
}

.page-header {
	width: 100%;
	height: 342px;
	background-image: url("assets/banner-headerbg.png");
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;

	.close-button {
		color: #FFFFFF;
		position: absolute;
		right: 20px;
		top: 10px;
		padding: 2px 8px;
		line-height:20px;
		border-radius: 2px;
		box-sizing: border-box;
		border: 1px solid #FFFFFF;
	}

	.title {
		padding-top: 20px;
		font-size: 24px;
		font-family: PingFang-SC-Heavy, PingFang-SC;
		font-weight: 800;
		color: #FFFFFF;
		line-height: 34px;
		text-shadow: 0px 2px 4px #F2442E;
	}

	.tips {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 26px;
	}

	.tips-1, .tips-2 {
		font-size: 20px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 26px;
		text-shadow: 0px 2px 4px #E02A14;
	}

	.tips-1__important {
		font-size: 26px;
	}

	.tips-2__important {
		font-size: 24px;
		color: #FFE561;
	}

}
</style>
